import { supplierLevelService } from '@/api';
import { SupplierLevelResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
@Component({
  name: 'add-supplier-level'
})
export default class AddSupplierLevel extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public supplierLevel!: SupplierLevelResource | null;
  public supplierLevelForm: {
    name: string;
    explain: string;
    serial: string;
  } = {
    name: '',
    serial: '',
    explain: ''
  };

  public supplierLevelFormRules = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplierLevel.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          supplierLevelService
            .checkNameRepeat(value, this.supplierLevel?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    serial: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplierLevel.selectSerial')));
            return;
          }
          callback();
        },
        trigger: 'change'
      },
      {
        validator: this.checkSerialRepeatRule,
        trigger: 'change'
      }
    ]
  };

  public serials: Array<number> = [...Array(20).keys()].map((x: number) => x + 1);

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (!this.supplierLevel) {
      this.operationType = 'add';
      this.title = 'supplierLevel.addSupplierLevel';
      return;
    }
    this.operationType = 'edit';
    this.title = 'supplierLevel.editSupplierLevel';
    this.$nextTick(() => {
      this.supplierLevelForm = Object.assign(this.supplierLevelForm, this.supplierLevel);
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.supplierLevelForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.supplierLevelForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await supplierLevelService.post({ ...this.supplierLevelForm } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await supplierLevelService.put({ ...this.supplierLevelForm } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.supplierLevelForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  private checkSerialRepeatRule(rule: any, value: number, callback: Function): void {
    if (this.operationType === 'edit') {
      callback();
      return;
    }
    supplierLevelService
      .checkSerialRepeat(value)
      .then((isRepeat: boolean) => {
        if (isRepeat) {
          callback(translation('requestError.supplierSequenceRepeat'));
          return;
        }
        callback();
      })
      .catch(error => {
        callback(error);
      });
  }
}
