import AddSupplierLevel from './add-supplier-level/add-supplier-level.vue';
import { OperationOption } from './../../../components/os-table-operation/os-table-operation';
import { Component, Vue } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { SupplierLevelResource } from '@/resource/model';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { supplierLevelService } from '@/api';
import { Message, MessageBox } from 'element-ui';
import { cloneDeep } from 'lodash-es';
import { messageError, translation } from '@/utils';
import PermissionConfig from './permission-config/permission-config.vue';
import { MessageBoxData } from 'element-ui/types/message-box';
import { ApiResponse } from '@/api/axios';
@Component({
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, AddSupplierLevel, PermissionConfig }
})
export default class SupplierLevel extends Vue {
  public tableOption: OsTableOption<SupplierLevelResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<SupplierLevelResource>> = [
    {
      prop: 'serial',
      label: 'supplierLevel.serial',
      minWidth: '150px'
    },
    {
      prop: 'name',
      label: 'supplierLevel.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'explain',
      label: 'supplierLevel.explain',
      minWidth: '260px'
    },
    {
      prop: 'count',
      label: 'supplierLevel.companyCount',
      showOverflowTooltip: true,
      minWidth: '130px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'system:supplier:grade:save',
      handleClick: (): void => {
        this.addSupplierLevel();
      }
    }
  ];
  public editRow: SupplierLevelResource | null = null;

  public empowerParams: { name: string; id: number } = { name: '', id: 0 };

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<SupplierLevelResource> = {
    fixed: 'right',
    width: '270px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'system:supplier:grade:edit',
        handleClick: (item: SupplierLevelResource): void => {
          this.editSupplierLevel(item);
        }
      },
      {
        operationType: 'permission',
        type: 'text',
        label: 'platformPermission.permissionConfig',
        icon: 'el-icon-set-up',
        permissionCode: 'system:supplier:grade:empowerment',
        handleClick: (item: SupplierLevelResource): void => {
          this.openEmpowerDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'system:supplier:grade:delete',
        handleClick: (item: SupplierLevelResource): void => {
          this.deleteSupplierLevel(item);
        }
      }
    ]
  };

  public supplierLevelDialogVisible = false;

  public empowerDialogVisible = false;

  public totalData = 0;

  private queryForm: Partial<{ keywords: string; status: number | null }> = {
    keywords: '',
    status: null
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public editSuccess(data: SupplierLevelResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  private addSupplierLevel(): void {
    this.openSupplierLevelDialog();
  }

  private editSupplierLevel(item: SupplierLevelResource): void {
    this.openSupplierLevelDialog(item);
  }

  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }

  private deleteSupplierLevel(data: SupplierLevelResource): void {
    this.deleteConfirm()
      .then(async () => {
        try {
          await supplierLevelService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openSupplierLevelDialog(data: SupplierLevelResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.supplierLevelDialogVisible = true;
  }

  private openEmpowerDialog(data: SupplierLevelResource): void {
    this.empowerParams = {
      name: data.name,
      id: data.id
    };
    this.empowerDialogVisible = true;
  }

  private loadData(): void {
    this.tableOption.loading = true;
    supplierLevelService
      .getList(this.queryForm as SupplierLevelResource, this.paging)
      .then((res: ApiResponse<Array<SupplierLevelResource>>) => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
